export interface CustomerInterface {
  id: number;
  edit?:boolean;
  photo: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  end_value: string;
  stamp_end_value: string;
  total_spend: string;
  check_in_count: string;
  spend_balance?: string;
  used_spend_balance?: string;
  last_visit: string;
}

export interface CustomerInfoInterface {
  count_users: number;
  limit: number;
  page: number;
  total_pages_count: number;
  total_users_count: number;
  // searchType: number;
  startDate?: any;
  endDate?: any;
}

export interface CustomerUserInterface {
  first_name: string;
  last_name: string;
  points: string;
  date: string;
  promotion_name: string;
  photo: string;
  activity_id: string;
  seller: string;
  can_edit: boolean;
  min_points: number;
  used_points: number;
  pos_id?: string;
  pos_user_id?: string;
  store?: string;
  receipt?: string;
  used_vouchers: any[];
  vouchers: any[];
  stamp_activity?: {
    earned: number,
    stamps: number,
    used: number,
  }[];
}

export interface PointCountsInterface {
  points: string;
  activity_id: string;
}

export interface CustomerRequestInterface {
  limit: string;
  page: string;
  search?: string | null;
  location?: string;
  searchType?: string;
  group?: string;
  business?: string | null;
  filters?: CustomerGroupChoosedFilterInterface[];
  sort_type?: string;
  startDate?: any;
  endDate?: any;
}

export const customerPerPage = [
  {
    value: "10",
    label: "10",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "100",
    label: "100",
  },
];

export interface CustomerGroupsRequestInterface {
  limit: string;
  page: string;
  search: string | null;
}

export interface CustomerGroupsInterface {
  id: number;
  name: string;
  description: string;
  group_type: string;
  group_type_id: string;
  group_type_title: string;
  total_groups_count: string;
}

export interface CustomerGroupsInfoInterface {
  count_groups: number;
  limit: number;
  page: number;
  total_pages_count: number;
  total_groups_count: number;
}

export interface GroupTypeInterface {
  value: string;
  label: string;
}

export interface GroupFilterInterface {
  value: string;
  label: string;
}

export interface CustomerGroupInterface {
  group_name: string;
  group_description: string;
  group_type: string | null;
}

export interface CustomerGroupCreateRequestInterface {
  id?: string;
  group_name: string;
  group_description: string;
  group_type: string | null;
  users?: Array<number>;
  filters?: Array<CustomerGroupChoosedFilterInterface>;
}

export interface CustomerGroupChoosedFilterInterface {
  virtualId: number;
  filter_type_id: string;
  value: string;
}
