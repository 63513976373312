import React, {useCallback, useEffect, useState} from 'react';
import {Wrapper} from './styled'
import AdminPartnersItem from "../../../components/AdminPartnersItem";
import {AdvertsPartnerType} from "../../../types/adminTypes";
import {getAdvertPartnersAxios, getShowAdvertInAppAxios, setShowAdvertInAppAxios} from "../../../api/adminPanel";
import CheckBox from "../../../components/CheckBox";

const AdminAdverts = () => {
  const [partners, setPartners] = useState<AdvertsPartnerType[]>([])
  const [showInMobile, setShowInMobile] = useState(false)
  
  const getShowInMobileApp = useCallback(()=>{
    getShowAdvertInAppAxios().then(res=>{
      setShowInMobile(res.data.showAdverts)
    })
  },[])
  
  const setShowInMobileApp = useCallback((showInMobile: boolean)=>{
    setShowAdvertInAppAxios({showAdverts: showInMobile}).then(res=>{
      getShowInMobileApp()
    })
  },[getShowInMobileApp])
  
  const handlerGetPartner = useCallback(() => {
    getAdvertPartnersAxios().then(({data}) => {
      let dataPartner: AdvertsPartnerType[] = []
      data.advertPartners.forEach((partner: any) => {
        dataPartner.push({name: +partner.business.id, id: partner.id, logo: partner.logoUrl})
      })
      setPartners([...dataPartner])
    })
  }, [])
  
  useEffect(() => {
    handlerGetPartner()
    getShowInMobileApp()
  }, []);
  
  return (
    <Wrapper>
      <div className="header">
        <h2>In-App Advertisements</h2>
      </div>
      <div className="show_wrap">
        <CheckBox label={'Show in mobile App'} isChecked={showInMobile} onChange={(checked: boolean) => {
          setShowInMobileApp(checked);
          setShowInMobile(checked)
        }}/>
      </div>
      <div className="main_advert">
        <div className="subtitle">
          <h3>Featured Partners</h3>
        </div>
        <AdminPartnersItem setPartners={setPartners} type={'partner'} advertList={partners} offers={false}
                           getAllPartners={handlerGetPartner}/>
      </div>
    </Wrapper>
  );
};

export default AdminAdverts;
